import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  TextField,
  Button,
  CardContent,
  CardActions,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import side from "../assets/icons/side.png";
import SearchSections from "../sections/SearchSections";

const HomeScreen = () => {
  const navigate = useNavigate();
  const services = [
    {
      title: "I want to hire a contractor",
      icon: "🛠️",
      buttonText: "Build",
    },
    {
      title: "I want to hire an architect",
      icon: "🏗️",
      buttonText: "Design",
    },
    {
      title: "I want to hire an interior designer",
      icon: "🛋️",
      buttonText: "Decorate",
    },
  ];

  const testimonials = [
    {
      name: "John Doe",
      avatar: "https://via.placeholder.com/150",
      title: "Homeowner",
      quote:
        "ApniChhat helped me find the perfect contractor for my home renovation. The process was seamless and stress-free.",
    },
    {
      name: "Jane Smith",
      avatar: "https://via.placeholder.com/150",
      title: "Architect",
      quote:
        "As an architect, I love the network ApniChhat provides. I've connected with several clients through their platform.",
    },
    {
      name: "Michael Johnson",
      avatar: "https://via.placeholder.com/150",
      title: "Interior Designer",
      quote:
        "Finding new projects has never been easier thanks to ApniChhat. Highly recommended for professionals in the industry.",
    },
  ];

  const faqs = [
    {
      question: "What services does ApniChhat provide?",
      answer:
        "ApniChhat connects you with a wide range of professionals including architects, contractors, and interior designers to help you with your building and renovation needs.",
    },
    {
      question: "How do I find the right professional for my project?",
      answer:
        "You can use our search function to find professionals in your area and filter by the specific service you need. Once you find a professional, you can view their profile and contact them directly.",
    },
    {
      question: "What is the process of hiring a contractor through ApniChhat?",
      answer:
        "The process is simple: search for contractors in your area, view their profiles, and reach out to them directly to discuss your project. We recommend meeting with a few contractors to find the best fit for your needs.",
    },
    {
      question: "Is there a cost to use ApniChhat?",
      answer:
        "ApniChhat is free to use for homeowners and project managers. We connect you with professionals at no charge.",
    },
    {
      question: "How can I ensure the quality of the professionals listed?",
      answer:
        "All professionals on our platform are vetted based on their credentials, experience, and customer reviews. We recommend reviewing their portfolio and client feedback to ensure they meet your standards.",
    },
  ];

  const steps = [
    {
      step: "Step 1",
      title: "Create an Account",
      description:
        "Sign up with your email or social media accounts and start exploring the wide range of services we offer.",
      icon: "👤",
    },
    {
      step: "Step 2",
      title: "Search for Professionals",
      description:
        "Use our powerful search tool to find the best architects, contractors, and interior designers in your area.",
      icon: "🔍",
    },
    {
      step: "Step 3",
      title: "Connect & Collaborate",
      description:
        "Reach out to professionals directly, view their portfolios, and start collaborating on your next project.",
      icon: "🤝",
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          py: 8,
          backgroundImage: "linear-gradient(to right, #01AEFE, #01AEFE)",
          color: "#fff",
        }}
      >
        <Container>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: "center", md: "flex-start" }}
              >
                <Typography
                  variant="h3"
                  align="center"
                  gutterBottom
                  sx={{ color: "#fff", fontWeight: "bold" }}
                >
                  Welcome to Apni Chhat.
                </Typography>
                <Typography variant="body1" sx={{ color: "#f0f0f0" }}>
                  We are dedicated to connecting clients with top-tier
                  construction firms, and innovative architects and interior
                  designers, all under one roof. With Apni Chhat, you can easily
                  browse profiles, view portfolios, and select the perfect team
                  to execute your vision; we’ve got the right professionals to
                  help you every step of the way. From planning to execution, we
                  ensure that your ideas meet expertise to turn your vision into
                  reality. From concept to completion, Apni Chhat is your
                  partner in building spaces that inspire.
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                component="img"
                src={side}
                alt="Illustration"
                sx={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "10px",
                  boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Search Section */}
      {/* <SearchSections /> */}

      {/* Service Options Section */}
      <Box sx={{ py: 8, backgroundColor: "#fff", textAlign: "center" }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            What are you looking for?
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Fill out the relevant form so that our staff can help you find the
            best solutions to solve your building problems. It will take a
            minute of your time, and set you on the path to systemic decision
            making during the construction of your home.
          </Typography>

          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#01AEFE",
                    color: "white",
                    textAlign: "center",
                    height: "100%",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                  onClick={() => navigate("/contractors")}
                >
                  <CardContent>
                    <Box sx={{ fontSize: "60px", mb: 2 }}>{service.icon}</Box>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        minHeight: "48px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {service.title}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#1565c0",
                        },
                      }}
                    >
                      {service.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 8, backgroundColor: "#f5f5f5", textAlign: "center" }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            What Our Clients Say
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Hear from our satisfied clients about their experiences with
            ApniChhat.
          </Typography>

          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    color: "#333",
                    textAlign: "center",
                    height: "100%",
                    boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <CardContent>
                    <Avatar
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      sx={{
                        width: 80,
                        height: 80,
                        margin: "0 auto 16px",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      {testimonial.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {testimonial.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                        color: "#555",
                        fontStyle: "italic",
                      }}
                    >
                      "{testimonial.quote}"
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Calculator Option Section */}
      <Box sx={{ py: 8, backgroundColor: "#fff", textAlign: "center" }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            Construction Cost Calculator
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Estimate the cost of building your dream house. Click below to
            access the construction cost calculator.
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  textAlign: "center",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  p: 4,
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Calculate Now
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#fff",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => navigate("/calculator")}
                  >
                    Go to Calculator
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Sign Up Section */}
      <Box sx={{ py: 10, backgroundColor: "#f0f4f8", textAlign: "center" }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#333",
              fontWeight: "bold",
              mb: 4,
              fontSize: "2.5rem",
            }}
          >
            Join ApniChhat as a Company
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              color: "#555",
              mb: 6,
              fontSize: "1.2rem",
              lineHeight: "1.6",
            }}
          >
            Create a company account and get access to powerful tools to manage
            projects, connect with professionals, and grow your business. Join
            our network of trusted companies today!
          </Typography>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Card
                sx={{
                  background: "linear-gradient(135deg, #01AEFE, #42a5f5)",
                  color: "#fff",
                  boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
                  textAlign: "center",
                  borderRadius: "16px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
                  },
                  p: 4,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "3rem",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        color: "#1976d2",
                        padding: "20px",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                      }}
                    >
                      🏢
                    </Box>
                  </Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.8rem",
                      mb: 2,
                    }}
                  >
                    Sign Up as a Company
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#f0f0f0",
                      fontSize: "1.1rem",
                      mb: 3,
                    }}
                  >
                    Register your company to gain access to project management
                    tools and a network of professionals.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#fff",
                      color: "#1976d2",
                      fontWeight: "bold",
                      py: 1.5,
                      borderRadius: "8px",
                      fontSize: "1.2rem",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 8, backgroundColor: "#f5f5f5", textAlign: "center" }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Here are some common questions about our platform and services. If
            you have more questions, feel free to contact us.
          </Typography>

          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>

      <Box
        sx={{
          py: 8,
          backgroundImage: "linear-gradient(to right, #01AEFE, #01AEFE)",
          color: "#fff",
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", color: "#fff" }}
          >
            Get Started with ApniChhat
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: "center", mb: 4, color: "#f0f0f0" }}
          >
            Follow these simple steps to begin your journey with us.
          </Typography>

          <Grid container spacing={4}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    color: "#1976d2",
                    textAlign: "center",
                    height: "100%",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: "40px",
                        mb: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {step.icon}
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      {step.step}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      {step.title}
                    </Typography>
                    <Typography variant="body2">{step.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: "center", mt: 6 }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "#f0f0f0",
                color: "#1976d2",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
              Get Started Now
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HomeScreen;